export default function slackContact({
  name,
  ticketUrl,
  email,
  company,
  phone,
  category,
  message
}: {
  name: string
  ticketUrl: string
  email: string | string[]
  company: string | string[]
  phone: string | string[]
  category: string | string[]
  message: string | string[]
}) {
  return JSON.stringify({
    blocks: [
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: ':warning: *New inquiry on Ichigo Public Sites!* @davidasikin @corporate'
        }
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*お名前/Name:*\n${name}`
        }
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*メールアドレス/Email:*\n${email}`
        }
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*会社名/Company name:*\n${company}`
        }
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*電話番号/Phone:*\n${phone}`
        }
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*お問合せのカテゴリー/Category:*\n${category}`
        }
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*メッセージ/Message:*\n${message}`
        }
      },
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `*チケット/Ticket:*\n${ticketUrl}`
        }
      }
    ]
  })
}
